@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Alice';
    src: url('./static/font/BerkshireSwash-Regular.ttf');
}

.bg-main {
    background-image: url('../src/static/images/bg-main.png');
}